export const ZoomConstants = {
  DEFAULT_MODEL_ZOOM: 0.945,
  DEFAULT_MODEL_ZOOM_LAYOUT360: 0.78,
  LANDING_PAGE_MODEL_ZOOM: 0.25,
  LANDING_PAGE_MODEL_ZOOM_AOHS: 0.78,
  COMPARE_MODE_MODEL_ZOOM: 0.70875,
  COMPARE_MODE_MODEL_ZOOM_SPLIT_SCREEN: 0.5,
  SPLIT_MODE_MODEL_ZOOM: 0.6,
  SPLIT_MODE_360_ZOOM: 0.75,
};

export const CanvasConstants = {
  CANVAS_SIZE_COEFFICIENT: 0.16,
};
