import { utils } from '../utils';
import logger from '../logger';
import { logToTimber } from '../timberLogger';
import { eventBus, globalEventsKeys, hostEvents } from '../event-bus';
import { AOHS_Status } from '../constants';
import { cacheKeys, cacheManager } from '../cache-manager';

const log = ({ message, action, data = '' }) => {
  logger
    .info(message)
    .to(['analytics', 'host'])
    .data({ module: 'AOHS Service', data })
    .end();

  logToTimber({
    timberData: {
      action,
      module: 'AOHS Service',
      type: 'page',
      actor: 'System',
      value: data,
    },
  });
};

export const cache = {
  isInitialized: false,
  isInClearLeanMode: false,
  isInCondition: false,
  functions: {},
};

export const AOHSService = {
  /**
   * Init method for AOHS Service.
   * @param {Object.<Function>} dispatch - is the redux dispatch method.
   * @param {Object.<Function>} loadAOHSPreset - shell.actions method.
   * @returns {void} Init Service.
   */
  init: (dispatch, loadPreset, loadAOHSPreset, loadNiri) => {
    cache.functions = { dispatch, loadAOHSPreset, loadPreset };
    const { isInitialized } = cache;

    const subscription = (sub) => {
      if (sub) sub.unsubscribe();
      cache.isInitialized = true;
      cache.isInClearLeanMode = true;

      const conditionNavigationSubscription = eventBus.subscribeToEvent(
        hostEvents.CONDITION_NAVIGATION,
        (condition) => {
          conditionNavigationSubscription && conditionNavigationSubscription.unsubscribe();
          log({
            message: 'lean mode first condition received, dispatching loadAOHSPreset...',
            action: 'navigation',
            data: condition,
          });

          const pluginsLoadedSubscription = eventBus.subscribeToEvent(globalEventsKeys.PLUGINS_LOADED, () => {
            pluginsLoadedSubscription && pluginsLoadedSubscription.unsubscribe();
            log({
              message: 'lean mode plugins loaded, raising CONDITION_NAVIGATION event...',
              action: 'navigation',
              data: condition,
            });

            // using setTimeout with no delay because we need to wait for the current call stack to clear before raising event for
            // condition navigation, this is because after the plugins are loaded they still initializing.
            setTimeout(() => {
              eventBus.raiseEvent(hostEvents.CONDITION_NAVIGATION, condition);
            });
          });

          cache.isInCondition = true;

          const arr = [];
          const niriData = cacheManager.get(cacheKeys.NIRI_DATA);

          if (loadNiri && condition) {
            if (niriData) {
              eventBus.raiseEvent(globalEventsKeys.NIRI_LOADED, niriData);
            } else {
              const niriLoadAction = loadNiri({ includesNiriData: false });
              arr.push(niriLoadAction);
            }
          }

          arr.push(
            loadAOHSPreset({
              presetProps: {
                pluginsPresetId: 'default',
              },
            })
          );

          dispatch(arr);
        }
      );
      log({ message: 'AOHSService initialized', action: 'init' });
    };

    if (!isInitialized) {
      const leanModeSubscription = eventBus.subscribeToEvent(hostEvents.LEAN_AOHS_MODE, () =>
        subscription(leanModeSubscription)
      );
      const clearModeSubscription = eventBus.subscribeToEvent(globalEventsKeys.RESET_AOHS_PLUGINS_AVAILABILITY, () =>
        subscription(clearModeSubscription)
      );
    }
  },

  reset: () => {
    const { dispatch, loadPreset, loadAOHSPreset } = cache.functions;
    cache.isInCondition = false;
    cache.isInClearLeanMode = true;
    cache.isInitialized = false;
    const arr = [loadPreset({ pluginsPresetId: 'aohs_lean_mode_clear' })];
    dispatch(arr);
    AOHSService.init(dispatch, loadPreset, loadAOHSPreset);
  },

  /**
   * Get AOHS Service Status.
   * @returns {String} one of AOHS_Statuses.
   */
  getAOHSModeStatus: () => {
    const isAOHS = utils.isAOHSEnv();
    const { isInCondition, isInClearLeanMode } = cache;
    const aohsInCondition = isInClearLeanMode && isInCondition;
    switch (true) {
      case isAOHS && aohsInCondition:
        return AOHS_Status.CONDITION_MODE;
      case isAOHS && !aohsInCondition:
        return AOHS_Status.CLEAR_MODE;
      default:
        return AOHS_Status.NO_AOHS_MODE;
    }
  },

  /**
   * Get is AOHS in condition mode.
   * @returns {Boolean} is AOHS initialized and loaded condition.
   */
  isAOHSInConditionMode: () => {
    const isAOHS = utils.isAOHSEnv();
    if (isAOHS) {
      const aohsStatus = AOHSService.getAOHSModeStatus();
      return aohsStatus === AOHS_Status.CONDITION_MODE;
    }
    return false;
  },

  /**
   * Get is AOHS in clear mode.
   * @returns {Boolean} is AOHS initialized and loaded condition.
   */
  isAOHSInClearMode: () => {
    const isAOHS = utils.isAOHSEnv();
    if (isAOHS) {
      const aohsStatus = AOHSService.getAOHSModeStatus();
      return aohsStatus === AOHS_Status.CLEAR_MODE;
    }
    return false;
  },
};
