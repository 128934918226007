import { getJawByObjectKey } from '../model-logic';
import { calculateIntersectPointForImageSelection, onImageSelectedUpdateRotation } from './lumina/lumina.common.logic';
import { selectBestMatchImageByRails } from './lumina/algorithms/rails-image-selection-alg';
import { selectBestMatchImageByPenalty } from './lumina/algorithms/penalty-image-selection-alg';
import { cacheManager, cacheKeys } from '../cache-manager';
import { toolsEvents } from '../event-bus/supportedKeys';

const { EVENT_ORIGINS } = toolsEvents;

export const getClosestPhotoObjectLumina = ({
  jawName,
  intersect,
  jawsPhotosMetadata,
  camera,
  rayCaster,
  eventOrigin,
  meshes,
  viewer360Align2DImages,
  modelType,
  isuminaBestScoreAlgorithmAvaliable,
}) => {
  const { imagesMetadataCacheKey } = jawsPhotosMetadata;
  const imagesMetadata = cacheManager.get(imagesMetadataCacheKey);
  const luminaImageMetaData = imagesMetadata && imagesMetadata[jawName].images;
  const mesh = meshes.find((mesh) => getJawByObjectKey(mesh.name) === jawName);

  if (!mesh || intersect.length === 0 || !luminaImageMetaData || luminaImageMetaData.length === 0) {
    return null;
  }

  const { matrixWorldInverse } = camera;
  const { ray } = rayCaster;
  const rayFromLoupeViewDirection = ray.direction;

  intersect.point = calculateIntersectPointForImageSelection(eventOrigin, intersect, ray, mesh);

  const selectedImage = selectBestMatchFrameFunction(
    meshes,
    eventOrigin,
    jawName,
    rayFromLoupeViewDirection,
    intersect,
    jawsPhotosMetadata,
    luminaImageMetaData,
    modelType,
    isuminaBestScoreAlgorithmAvaliable
  );

  if (selectedImage && selectedImage.image) {
    return onImageSelectedUpdateRotation(
      selectedImage,
      matrixWorldInverse,
      luminaImageMetaData,
      viewer360Align2DImages,
      isuminaBestScoreAlgorithmAvaliable
    );
  }

  return null;
};

const selectBestMatchFrameFunction = (
  meshes,
  eventOrigin,
  jawName,
  rayFromLoupeViewDirection,
  intersectFromModelRotation,
  jawsPhotosMetadata,
  luminaImageMetaData,
  modelType,
  isuminaBestScoreAlgorithmAvaliable
) => {
  const currentActiveJaw = jawsPhotosMetadata[jawName];

  if (isuminaBestScoreAlgorithmAvaliable) {
    return selectBestMatchImageByPenalty(
      meshes,
      isuminaBestScoreAlgorithmAvaliable,
      rayFromLoupeViewDirection,
      jawName,
      intersectFromModelRotation,
      currentActiveJaw,
      luminaImageMetaData
    );
  } else {
    const grid = cacheManager.get(cacheKeys.CANDIDATE_GRID);
    const { candidate_grid, surface_projection_segmentation_grid } = (grid && grid[modelType][jawName]) || {};
    const isStaticModelAlgoCalculated = candidate_grid && surface_projection_segmentation_grid;

    return eventOrigin === EVENT_ORIGINS.MODEL_ROTATION || !isStaticModelAlgoCalculated
      ? selectBestMatchImageByPenalty(
          meshes,
          isuminaBestScoreAlgorithmAvaliable,
          rayFromLoupeViewDirection,
          jawName,
          intersectFromModelRotation,
          currentActiveJaw,
          luminaImageMetaData
        )
      : selectBestMatchImageByRails(
          intersectFromModelRotation,
          candidate_grid,
          surface_projection_segmentation_grid,
          currentActiveJaw,
          luminaImageMetaData
        );
  }
};
